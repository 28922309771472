import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import About from 'components/About';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

const AboutPage: React.FC = function () {
  const { t } = useI18next();

  return (
    <Layout id="about">
      <SEO title="About" />
      <About/>
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
