import React from 'react';
import * as classes from './About.module.scss';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import { useI18next } from 'gatsby-plugin-react-i18next';
import aboutImage from '../../assets/images/avocat_szilagyi_kinga.jpeg';
import CopyText from '../CopyText';

const About: React.FC = () => {
  const { t } = useI18next();

  return (
    <Container section>
      <div className="flex flex-col md:flex-row items-center justify-center">
        <img className={classes.frame} src={aboutImage} alt="Szilagyi Kinga Lawyer" title="Szilagyi Kinga Lawyer" />
        <div className="flex flex-col items-start justify-start p-20">
          <TitleSection subtitle={t('aboutLawyer')} noSeparator className="mb-16" />
          <div className="flex flex-col items-start">
            <CopyText variant="copy-2" spaced additionalClass="mb-10 text-justify">
              <span dangerouslySetInnerHTML={{ __html: t('aboutLawyerOne') }} />
            </CopyText>
            <CopyText variant="copy-2" spaced additionalClass="mb-10 text-justify">
              {t('aboutLawyerTwo')}
            </CopyText>
            <CopyText variant="copy-2" spaced additionalClass="mb-10 text-justify">
              {t('aboutLawyerThree')}
            </CopyText>
            <CopyText variant="copy-2" spaced additionalClass="mb-10 text-justify">
              {t('aboutLawyerFour')}
            </CopyText>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default About;
